@media (min-width: 576px) {
    .nav-user-menu .user-image {
        float: none;
        line-height: 10px;
        margin-top: -8px;
    }
}

.nav-user-menu .user-image {
    border-radius: 50%;
    float: left;
    height: 2rem;
    margin-top: -4px;
    width: 2rem;
}

.form-group.required .col-form-label:after {
    color: #d00;
    content: '*';
    position: absolute;
    margin-left: 3px;
}

/* Sweet alert */
.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

div.wrapper,
div.content-wrapper,
div.sidebar {
    min-height: 100vh;
}

table.table {
    * {
        font-size: 14px !important;
    }

    th,
    td {
        padding: 8px;
        vertical-align: middle;
    }
}

table-card-footer * {
    font-size: 14px;
}

/* ng-select */
.ng-select .ng-select-container {
    min-height: 38px !important;
}

.ng-select.ng-select-single .ng-select-container {
    height: 31px !important;
}

.input-group-append .ng-select .ng-select-container {
    border-radius: 0 4px 4px 0 !important;
}

ng-select.form-control {
    padding: initial !important;
}

.form-control .ng-select-container {
    position: initial !important;
    border: 0px;
    background: transparent !important;
    min-height: initial !important;
    height: inherit !important;
}

.form-control ng-value-container {
    padding-top: 0px !important;
}

bs-tooltip-container {
    transition-duration: 0ms;
}

.freeze-ui {
    position: fixed !important;
}

@import '../shared/style/partials';
