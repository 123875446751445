.video-streams {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 auto;
}

.remote-video {
    object-fit: contain;
}

.local-stream {
    position: fixed;
    object-fit: contain;
    bottom: 0;
    right: 0;
    z-index: 1;

    &.local-stream-mobile {
        bottom: 0.5%;
        right: 1%;
    }
}
