.swal2-footer {
    justify-content: center;
    border-top: 1px solid #eee;
    color: inherit;
    font-size: 1em;
}

.footer-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

